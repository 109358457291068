
/* 
MAJOR COLORS
Baby Blue - #DBEEFF
Bright Brown - #964B00
Fonts - Phase-CGX_0-74-16.woff2
Fonts - Arial 
*/
@font-face {
  font-family: "Phase-CGX";
  src: url("./fonts/Phase-CGX_0-74-16.woff2") format("woff2");
}
h1,h2,h3{
	font-weight:normal;
	font-size:inherit;
	margin:0;
}
ul{
	padding-left:0;
	list-style: none;
	margin:0;
}
li{
	margin-bottom:10px;
}
p{
	margin:0;
}
a:link, a,a:hover, a:visited, a:focus{
	color:inherit;
	text-decoration: none;
	display: inline-block;
}
a:hover{
	font-style: italic;
}
a.nav::after{
	display: none;
}
a p{
	display: inline-block;
	margin:0;
}
a::after{
/*	content:"→";
	position: absolute;
	display: inline-block;
	margin-left:5px;*/
}
body {
  color: #964B00;
  background: #DBEEFF;
  margin: 0;
  font-size: 18px;
  font-family: 'Arial', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.projects-wrapper{
	width: calc(100% - 40px);
	height:calc(100vh - (82px + 98px));
	overflow: scroll;
}
::-webkit-scrollbar-corner {
	border-left:1px solid #964B00;
  background: rgba(0,0,0,0);
}
/* width */
.projects-wrapper::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.projects-wrapper::-webkit-scrollbar-track {
  background: transparent; 
  border-left:1px solid #964B00;
}
 
/* Handle */
.projects-wrapper::-webkit-scrollbar-thumb {
  background: #964B00; 
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  box-sizing:border-box;
}

/* Handle on hover */
.projects-wrapper::-webkit-scrollbar-thumb:hover {
  background: #964B00; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.global-wrapper{
	display: flex;
	flex-wrap: nowrap;
}
main{
	flex-basis:85%;
}
.side-panel{
	flex-basis:15%;
}
@media(max-width: 1500px){
	main{
		flex-basis:80%;
	}
	.side-panel{
		flex-basis:20%;
	}
}
@media(max-width:1375px){
	body{
		font-size:16px;
	}
}
@media(max-width:1235px){
	body{
		font-size:14px;
	}
}

@media(max-width: 1136px){
	.global-wrapper{
		flex-direction: column;
	}
	main{
		flex-basis:auto;
		
	}
	.projects-wrapper{
		height:auto;
		overflow: visible;
	}
	.side-panel{
		flex-basis:auto;
	}
	body{
		font-size:16px;
	}
	/* width */
	.projects-wrapper::-webkit-scrollbar {
	  width: 0px;
	  display: none;
	}

	/* Track */
	.projects-wrapper::-webkit-scrollbar-track {
	  background: transparent; 
	  border-left:1px solid #964B00;
	  display: none;
	}
	 
	/* Handle */
	.projects-wrapper::-webkit-scrollbar-thumb {
	  background: #964B00; 
	  border: 4px solid rgba(0, 0, 0, 0);
	  background-clip: padding-box;
	  box-sizing:border-box;
	  display: none;
	}

	/* Handle on hover */
	.projects-wrapper::-webkit-scrollbar-thumb:hover {
	  background: #964B00; 
	  display: none;
	}
}
