.side-panel{
	border-left:1px solid #964B00;
	min-height: 100vh;
}
.side-panel .accordion-trigger h1, .accordion-trigger span{
	display: inline-block;
}
.side-panel .accordion::after{
	content: " ";
    display: block;
    border-top: 1px solid #964B00;
    border-bottom: 1px solid #964B00;
    height: 2px;
    position: absolute;
    bottom: -7px;
    top: auto;
    width: 100%;
    transition:0.2s;
}
.side-panel .accordion-trigger{
	display: block;
	padding:10px;
	cursor: pointer;
	border-top:1px solid #964B00;
}
.side-panel .accordion-trigger h1{
	margin-right:10px;
}
.side-panel .accordion.open::after{
	border-bottom:none;
	bottom: -6px;
}
.side-panel .accordion{
	position:relative;
	border-bottom:1px solid #964B00;
}
.side-panel .accordion-inner a::after{
	opacity: 0;
	transition:0.2s;
	pointer-events: none;
}
.side-panel .accordion-inner{
	max-height:0;
	transition:0.2s;
	overflow:hidden;
}
.side-panel .accordion.open .accordion-inner{
	max-height: 1000px;
	transition:2s;
}

.side-panel .accordion.open .accordion-inner a::after{
	opacity: 1;
	pointer-events: auto;
}
.side-panel .accordion .accordion-trigger svg{
	transition:0.2s;
}
.side-panel .accordion.open .accordion-trigger svg{
	transform:scaleY(-1);
}
.signature{
	padding:10px;
	padding-top:20px;
}
.signature .img-wrapper img{
	width: 300px;
}
.signature .gif-wrapper{
	position: relative;
	display: block;
	margin:0;
	width: 200px;
	margin-top:20px;
	height:200px;
}

.signature .img-wrapper{
	left:0px;
	top:0px;
	transform-origin: center;
	width: 200px;
	overflow:hidden;
	height:200px;
	position:absolute;
}
.signature .img-wrapper:first-child{
	animation: zoom 5s infinite;
	animation-delay: 2.5s;
}

.signature .img-wrapper:last-child{
	animation: zoom 5s infinite;
}

@keyframes zoom {
  0%    { transform:scale(0);z-index: 2;}
  50%  { transform:scale(1); z-index:2;}
  100%  { transform:scale(1); z-index:-1;}
}
.news-item{
	max-width: calc(100% - 30px);
	padding-left:10px;
}
.accordion-inner{
	max-width: calc(100% - 60px);
	padding:0 20px;
}
.collaborations-wrapper .accordion-trigger{
	border-top:none;
}
.recent-news-wrapper{
	padding:10px;
	border-bottom:1px solid #964B00;
}
.recent-news-wrapper > h1{
	margin-bottom:20px;

}
.beating-heart{
	margin-top:5px;
	position: relative;
	padding:10px;
	border-bottom:1px solid #964B00;
	padding-bottom:20px;
}
.beating-heart::after{
	content: " ";
    width: 100%;
    height: 300px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
    background: #964b00;
}

.beating-heart > div{
	height: 200px;
}
.beating-heart iframe{
	width: 95% !important;
	display: block;
	margin: 10px 0;
	height: 200px !important;
	filter: contrast(1) grayscale(2);
}
@media(max-width: 1136px){
	.signature .gif-wrapper{
		padding-bottom: 40px;
	}
	.recent-news-wrapper{
		margin-top:10px;
	}
	.side-panel{
		border-left:0;
		min-height:auto;
		overflow:visible;
	}
	.side-panel .recent-news-wrapper li{
		margin-left:20px;
	}
	.collaborations-wrapper li{
		margin-left:20px;
	}
	.beating-heart iframe{
		width: 60% !important;
	}
	.side-panel .accordion svg{
		float:right;
	}
	.side-panel .accordion-trigger, .side-panel .accordion{
		border-top:none;
	}
	.side-panel .accordion-trigger{
		padding-top:18px;
	}
	.side-panel .accordion-inner{
		max-height:1000px;
	}
	.side-panel .accordion-trigger svg{
		display: none;
	}
	.side-panel .accordion::after{
		display: none;
	}

}