
.spotify-wrapper::after{
	content: " ";
    width: 100%;
    height: 195vh;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
    background: #964b00;
}

.spotify-wrapper iframe{
    width: 50% !important;
	filter: contrast(2) grayscale(2);
}
@media(max-width: 1136px){
    .spotify-wrapper{
        width: 80%;
    }
    .spotify-wrapper > *{
        margin-left:10px;
    }
    .spotify-wrapper iframe{
        width: 55% !important;
        height:200px !important;
    }
    .signature{
        position: relative;
        z-index: 2;
    }
}